import axios from 'axios'
import { sendAnalyticsEvent } from '@faststore/sdk'
import { useCallback } from 'react'
import { useSession } from 'src/sdk/session'
import { useCart } from 'src/sdk/cart'
import useKpiOrderAddToCart from 'src/hooks/useKpiOrderAddToCart'
import type { CurrencyCode, AddToCartEvent } from '@faststore/sdk'
import type { CartItem } from 'src/sdk/cart'
import type { ProductPropertieType } from 'src/components/ui/QuantitySelector/QuantitySelector'
import { getFeatureKpi } from 'src/utils/kpiToCart/kpiOrderBySearchParams'
import { getCookie } from 'src/utils/cookie'

import { useAddToCart } from '../analytics/hooks/useAddToCartEvent'
import { useUI } from '../ui/Provider'
import { cartStore } from './index'

export const useBuyButton = (item: CartItem | null) => {
  const { openCart } = useUI()
  const { id } = useCart()
  const {
    currency: { code },
    person,
  } = useSession()

  const { sendAddToCartEvent } = useAddToCart()

  const { setClickAddToCart } = useKpiOrderAddToCart({
    productCart: item ? [item] : null,
    appName: undefined,
    marketingTag: undefined,
    byParams: true,
  })

  async function setUTMCampaign() {
    const utmCampaign = getCookie('utm_vivara')

    if (utmCampaign) {
      try {
        await axios.post('/api/setMarketingData', {
          utmCampaign,
          orderformID: id,
        })
      } catch (error) {
        console.error(error)
      }
    }
  }

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()

      if (!item) {
        return
      }

      const productID =
        item?.itemOffered?.isVariantOf?.additionalProperty?.filter(
          (propertie: ProductPropertieType) => propertie.name === 'Cód'
        )

      const valueProductID: string = productID?.[0]?.value
        ? productID?.[0]?.value
        : item?.itemOffered?.gtin

      sendAddToCartEvent({
        currency: code,
        value: Number((item.price * item.quantity).toFixed(2)),
        item_id: item.itemOffered?.sku,
        item_name: item.itemOffered.name,
        price: item.listPrice,
        quantity: item.quantity,
        discount: Number(
          ((item.listPrice - item.price) * item.quantity).toFixed(2)
        ),
        item_brand: item.itemOffered.brand.name ?? null,
        item_collection:
          item.itemOffered.isVariantOf.additionalProperty.find(
            (property) =>
              property.name.toLowerCase() === 'coleção'.toLowerCase()
          )?.value ?? null,
        item_type: item.itemOffered.name?.match(/^\p{L}+/u)?.[0] ?? null,
        item_reference: valueProductID,
        item_material:
          item.itemOffered.isVariantOf.additionalProperty.find(
            (property) =>
              property.name.toLowerCase() === 'material'.toLowerCase()
          )?.value ??
          item.itemOffered.isVariantOf.additionalProperty.find(
            (property) =>
              property.name.toLowerCase() === '1-material'.toLowerCase()
          )?.value ??
          item.itemOffered.isVariantOf.additionalProperty.find(
            (property) =>
              property.name.toLowerCase() ===
              'material da pulseira'.toLowerCase()
          )?.value ??
          null,
        item_size:
          item.itemOffered.isVariantOf.additionalProperty.find(
            (property) =>
              property.name.toLowerCase() === 'tamanho'.toLowerCase()
          )?.value ??
          item.itemOffered.additionalProperty.find(
            (property) => property.name.toLowerCase() === 'aro'.toLowerCase()
          )?.value ??
          item.itemOffered.isVariantOf.additionalProperty.find(
            (property) =>
              property.name.toLowerCase() === 'comprimento aprox'.toLowerCase()
          )?.value ??
          item.itemOffered.isVariantOf.additionalProperty.find(
            (property) =>
              property.name.toLowerCase() === 'altura aprox'.toLowerCase()
          )?.value ??
          item.itemOffered.isVariantOf.additionalProperty.find(
            (property) =>
              property.name.toLowerCase() === 'largura aprox'.toLowerCase()
          )?.value ??
          null,
        item_stone:
          item.itemOffered.isVariantOf.additionalProperty.find(
            (property) => property.name.toLowerCase() === 'pedras'.toLowerCase()
          )?.value ??
          item.itemOffered.isVariantOf.additionalProperty.find(
            (property) =>
              property.name.toLowerCase() === 'pedra_valor'.toLowerCase()
          )?.value ??
          null,
        item_weight: item.itemOffered.name.match(/(\d+)\s*k/i)?.[0] ?? null,
        item_audience:
          item.itemOffered.isVariantOf.additionalProperty.find(
            (property) =>
              property.name.toLowerCase() === 'sugestão'.toLowerCase()
          )?.value ?? null,
        user_logged: !!person?.id,
        user_id: person?.id ?? null,
      })

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      sendAnalyticsEvent<AddToCartEvent, any>({
        name: 'add_to_cart',
        params: {
          currency: code as CurrencyCode,
          value: item?.price * item.quantity,
          items: [
            {
              item_id: item.itemOffered?.sku,
              item_name: item.itemOffered?.isVariantOf?.name,
              item_brand: item.itemOffered?.brand?.name,
              item_variant: item.itemOffered?.sku,
              quantity: item.quantity,
              price: item?.price,
              discount: item?.listPrice - item?.price,
              currency: code as CurrencyCode,
              item_variant_name: item.itemOffered?.name,
              product_reference_id: valueProductID,
              dimension1: item.itemOffered?.gtin,
            },
          ],
        },
      })

      cartStore.addItem(item)
      openCart()
      setUTMCampaign()

      if (getFeatureKpi()) {
        setClickAddToCart(true)
      }
    },
    [code, item, openCart]
  )

  return {
    onClick,
    'data-testid': 'buy-button',
    'data-sku': item?.itemOffered?.sku,
    'data-seller': item?.seller?.identifier,
  }
}
